import SyntheticsReader from 'abis/SyntheticsReader.json'
import { getContract } from 'config/contracts'
import { IS_VERBOSE } from 'config/development'
import { convertTokenAddress, getToken } from 'config/tokens'
import { ethers } from 'ethers'
import { useMulticall } from 'gmx/lib/multicall'
import { MarketsData } from './types'
import { getMarketFullName } from './utils'

export type MarketsResult = {
  marketsData?: MarketsData
  marketsAddresses?: string[]
}

const MARKETS_COUNT = 100

export function useMarkets(chainId: number): MarketsResult {
  const { data } = useMulticall(chainId, 'useMarketsData', {
    key: [chainId],

    request: () => ({
      reader: {
        contractAddress: getContract(chainId, 'SyntheticsReader'),
        abi: SyntheticsReader.abi,
        calls: {
          markets: {
            methodName: 'getMarkets',
            params: [getContract(chainId, 'DataStore'), 0, MARKETS_COUNT],
          },
        },
      },
    }),
    parseResponse: (res) => {
      return res.data.reader.markets.returnValues.reduce(
        (
          acc: { marketsData: MarketsData; marketsAddresses: string[] },
          marketValues: any,
        ) => {
          try {
            const indexToken = getToken(
              chainId,
              convertTokenAddress(chainId, marketValues.indexToken, 'native'),
            )
            const longToken = getToken(chainId, marketValues.longToken)
            const shortToken = getToken(chainId, marketValues.shortToken)

            const isSameCollaterals =
              marketValues.longToken === marketValues.shortToken
            const isSpotOnly = marketValues.indexToken === ethers.constants.Zero

            const name = getMarketFullName({
              indexToken,
              longToken,
              shortToken,
              isSpotOnly,
            })

            acc.marketsData[marketValues.marketToken] = {
              marketTokenAddress: marketValues.marketToken,
              indexTokenAddress: marketValues.indexToken,
              longTokenAddress: marketValues.longToken,
              shortTokenAddress: marketValues.shortToken,
              isSameCollaterals,
              isSpotOnly,
              name,
              data: '',
            }

            acc.marketsAddresses.push(marketValues.marketToken)
          } catch (e) {
            // eslint-disable-next-line no-console
            IS_VERBOSE && console.warn('unsupported market', e)
          }

          return acc
        },
        { marketsData: {}, marketsAddresses: [] },
      )
    },
  })

  return {
    marketsData: data?.marketsData,
    marketsAddresses: data?.marketsAddresses,
  }
}
