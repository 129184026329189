import { PriceFeed } from '@pythnetwork/pyth-evm-js'
import { TokenPriceInfo } from '@store/commonStore'
import {
  NATIVE_TOKEN_ADDRESS,
  TOKENS,
  Token,
  getWrappedToken,
} from 'config/tokens'
import { parseContractPrice } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'

export const tokenPythIdMapping = (chainId: number): Record<string, Token> => {
  const tokens = TOKENS[chainId]

  return tokens.reduce((acc, token: any) => {
    if (token.pythId) {
      return {
        ...acc,
        [token.pythId]: token,
      }
    }
    return acc
  }, {})
}

export const tokenbaseIdMapping = (chainId: number) => {
  const tokens = TOKENS[chainId]

  return tokens.reduce(
    (acc, token: any) => {
      if (token.baseId) {
        acc[token.baseId] = token
      }
      return acc
    },
    {} as Record<string, Token>,
  )
}

export const tokenPythIdMappingList = (chainId: number) =>
  Object.keys(tokenPythIdMapping(chainId))

export const getPriceFromPriceFeed = (priceFeed: any) => {
  const priceFeedInfo = priceFeed?.price
  const price = parseContractPrice(
    BigNumber.from(Number(priceFeedInfo.price)),
    Number(priceFeedInfo.expo) + 30,
  )

  return price
}

export const getPriceFeedTokenInfo = ({
  _tokenPythIdMapping,
  tokenId,
  priceFeed,
  chainId,
}: {
  _tokenPythIdMapping: ReturnType<typeof tokenPythIdMapping>
  tokenId: string
  priceFeed: PriceFeed
  chainId: number
}): Record<string, TokenPriceInfo> => {
  const tokenInfo: Record<string, TokenPriceInfo> = {}

  const tokenPythAddress = '0x' + tokenId
  const tokenInfoFromMapping = _tokenPythIdMapping[tokenPythAddress]
  const price = getPriceFromPriceFeed(priceFeed)
  if (tokenInfoFromMapping.address) {
    tokenInfo[tokenInfoFromMapping.address] = {
      minPrice: price,
      maxPrice: price,
    }
  }

  const wrappedToken = getWrappedToken(chainId)

  if (wrappedToken?.pythId === tokenPythAddress) {
    tokenInfo[NATIVE_TOKEN_ADDRESS] = tokenInfo[tokenInfoFromMapping.address]
  }

  return tokenInfo
}
