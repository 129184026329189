import { expandDecimals } from '@components/trade/gmx/tradingview/lib/numbers'
import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from 'config/tokens'
import { SetPendingDeposit } from 'context/SyntheticsEvents'
import { BigNumber, Signer, ethers } from 'ethers'
import { getGasLimit } from 'gmx/lib/contracts'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'
import { simulateExecuteOrderTxn } from '../orders/simulateExecuteOrderTxn'
import { TokensData } from '../tokens'
import { applySlippageToMinOut } from '../trade'

type Params = {
  account: string
  initialLongTokenAddress: string
  initialShortTokenAddress: string
  longTokenSwapPath: string[]
  shortTokenSwapPath: string[]
  marketTokenAddress: string
  longTokenAmount: BigNumber
  shortTokenAmount: BigNumber
  minMarketTokens: BigNumber
  executionFee: BigNumber
  allowedSlippage: number
  tokensData: TokensData
  skipSimulation?: boolean
  setPendingTxns: (txns: PendingTransaction[]) => void
  setPendingDeposit: SetPendingDeposit
  pricesUpdatedAt?: number
  sendPaymasterTransaction: SendPaymasterTransactionFn
}

export async function createDepositTxn(
  chainId: number,
  signer: Signer,
  p: Params,
) {
  const contract = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    signer,
  )

  const depositVaultAddress = getContract(chainId, 'DepositVault')

  const executionFee = expandDecimals(1, 15)

  const isNativeLongDeposit = Boolean(
    p.initialLongTokenAddress === NATIVE_TOKEN_ADDRESS &&
      p.longTokenAmount != undefined &&
      p.longTokenAmount.gt(0),
  )
  const isNativeShortDeposit = Boolean(
    p.initialShortTokenAddress === NATIVE_TOKEN_ADDRESS &&
      p.shortTokenAmount != undefined &&
      p.shortTokenAmount.gt(0),
  )

  let wntDeposit = BigNumber.from(0)

  if (isNativeLongDeposit) {
    wntDeposit = wntDeposit?.add(p.longTokenAmount!)
  }

  if (isNativeShortDeposit) {
    wntDeposit = wntDeposit.add(p.shortTokenAmount!)
  }

  const shouldUnwrapNativeToken = isNativeLongDeposit || isNativeShortDeposit

  const wntAmount = executionFee.add(wntDeposit)

  const initialLongTokenAddress = convertTokenAddress(
    chainId,
    p.initialLongTokenAddress,
    'wrapped',
  )
  const initialShortTokenAddress = convertTokenAddress(
    chainId,
    p.initialShortTokenAddress,
    'wrapped',
  )

  const minMarketTokens = applySlippageToMinOut(
    p.allowedSlippage,
    p.minMarketTokens,
  )

  const multicall = [
    { method: 'sendWnt', params: [depositVaultAddress, wntAmount] },

    !isNativeLongDeposit && p.longTokenAmount.gt(0)
      ? {
          method: 'sendTokens',
          params: [
            p.initialLongTokenAddress,
            depositVaultAddress,
            BigInt(p.longTokenAmount.toString()),
          ],
        }
      : undefined,

    !isNativeShortDeposit && p.shortTokenAmount.gt(0)
      ? {
          method: 'sendTokens',
          params: [
            p.initialShortTokenAddress,
            depositVaultAddress,
            BigInt(p.shortTokenAmount.toString()),
          ],
        }
      : undefined,

    {
      method: 'createDeposit',
      params: [
        {
          receiver: p.account,
          callbackContract: ethers.constants.AddressZero,
          market: p.marketTokenAddress,
          initialLongToken: initialLongTokenAddress,
          initialShortToken: initialShortTokenAddress,
          longTokenSwapPath: p.longTokenSwapPath,
          shortTokenSwapPath: p.shortTokenSwapPath,
          minMarketTokens: BigInt(minMarketTokens.toString()),
          shouldUnwrapNativeToken: shouldUnwrapNativeToken,
          executionFee: BigInt(executionFee.toString()),
          callbackGasLimit: 0n,
          uiFeeReceiver: ethers.constants.AddressZero,
        },
      ],
    },
  ]

  const encodedPayload = multicall
    .filter(Boolean)
    .map((call) =>
      contract.interface.encodeFunctionData(call!.method, call!.params),
    )

  if (!p.skipSimulation) {
    await simulateExecuteOrderTxn(chainId, {
      account: p.account,
      primaryPriceOverrides: {},
      secondaryPriceOverrides: {},
      tokensData: p.tokensData,
      createOrderMulticallPayload: encodedPayload,
      method: 'simulateExecuteDeposit',
      errorTitle: `Deposit error.`,
      value: wntAmount,
      signer,
    })
  }

  const calldata = encodeFunctionData({
    abi: ExchangeRouter.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(
    contract,
    'multicall',
    [encodedPayload],
    wntAmount,
  )
  const call: Parameters<typeof p.sendPaymasterTransaction>[0]['call'] = {
    address: getContract(chainId, 'ExchangeRouter'),
    calldata,
    gas: BigInt(gasLimit.toString()),
    value: BigInt(wntAmount.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  p
    ?.sendPaymasterTransaction({
      call,
      account: p.account,
      messageOpts,
      setPendingTxns: p.setPendingTxns,
      router: contract,
      payload: [encodedPayload],
      method: 'multicall',
    })
    .then((_res) => {
      p.setPendingDeposit({
        account: p.account,
        marketAddress: p.marketTokenAddress,
        initialLongTokenAddress,
        initialShortTokenAddress,
        longTokenSwapPath: p.longTokenSwapPath,
        shortTokenSwapPath: p.shortTokenSwapPath,
        initialLongTokenAmount: p.longTokenAmount,
        initialShortTokenAmount: p.shortTokenAmount,
        minMarketTokens: minMarketTokens,
        shouldUnwrapNativeToken,
      })
    })
}
