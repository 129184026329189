import { gql } from '@apollo/client'
import { BigNumber, BigNumberish, Signer, ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import ReferralStorage from 'abis/ReferralStorage.json'
import { REGEX_VERIFY_BYTES32 } from 'components/Referrals/referralsHelper'
import {
  ARBITRUM,
  AVALANCHE,
  DEFAULT_CHAIN_ID,
  SUPPORTED_CHAIN_IDS,
} from 'config/chains'
import { getContract } from 'config/contracts'
import { REFERRAL_CODE_KEY } from 'config/localStorage'
import { isAddress } from 'ethers/lib/utils'
import { contractFetcher, getGasLimit } from 'gmx/lib/contracts'
import { helperToast } from 'gmx/lib/helperToast'
import { isAddressZero, isHashZero } from 'gmx/lib/legacy'
import { basisPointsToFloat } from 'gmx/lib/numbers'
import { getProvider } from 'gmx/lib/rpc'

import { getReferralsGraphClient } from 'gmx/lib/subgraph'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { AnyArray } from 'immer/dist/internal'
import React from 'react'
import { Address, encodeFunctionData } from 'viem'
import { UserReferralInfo } from '../types'
import { decodeReferralCode, encodeReferralCode } from '../utils'

export * from './useReferralsData'

async function getCodeOwnersData(network: number, account: string, codes = []) {
  if (codes.length === 0 || !account || !network) {
    return undefined
  }
  const query = gql`
    query allCodes($codes: [String!]!) {
      referralCodes(where: { code_in: $codes }) {
        owner
        id
      }
    }
  `
  return getReferralsGraphClient(network)
    .query({ query, variables: { codes } })
    .then(({ data }) => {
      const { referralCodes } = data
      const codeOwners = referralCodes.reduce(
        (
          acc: { [x: string]: any },
          cv: { id: string | number; owner: any },
        ) => {
          acc[cv.id] = cv.owner
          return acc
        },
        {},
      )
      return codes.map((code) => {
        const owner = codeOwners[code]
        return {
          code,
          codeString: decodeReferralCode(code),
          owner,
          isTaken: Boolean(owner),
          isTakenByCurrentUser:
            owner && owner.toLowerCase() === account.toLowerCase(),
        }
      })
    })
}

export function useUserReferralInfo(
  signer: Signer | undefined,
  chainId: number,
  account?: string | null,
  skipLocalReferralCode = false,
): UserReferralInfo | undefined {
  const {
    userReferralCode,
    userReferralCodeString,
    attachedOnChain,
    referralCodeForTxn,
  } = useUserReferralCode(signer, chainId, account, skipLocalReferralCode)

  const { codeOwner } = useCodeOwner(signer, chainId, account, userReferralCode)
  const { affiliateTier: tierId } = useAffiliateTier(
    signer,
    chainId,
    codeOwner as any,
  )
  const { totalRebate, discountShare } = useTiers(signer, chainId, tierId)
  const { discountShare: customDiscountShare } = useReferrerDiscountShare(
    signer,
    chainId,
    codeOwner,
  )
  const finalDiscountShare = customDiscountShare?.gt(0)
    ? customDiscountShare
    : discountShare
  if (
    !userReferralCode ||
    !userReferralCodeString ||
    !codeOwner ||
    !tierId ||
    !totalRebate ||
    !finalDiscountShare ||
    !referralCodeForTxn
  ) {
    return undefined
  }

  return {
    userReferralCode,
    userReferralCodeString,
    referralCodeForTxn,
    attachedOnChain,
    affiliate: codeOwner,
    tierId,
    totalRebate,
    totalRebateFactor: basisPointsToFloat(totalRebate),
    discountShare: finalDiscountShare,
    discountFactor: basisPointsToFloat(finalDiscountShare),
  }
}

export function useAffiliateTier(
  signer: Signer | undefined,
  chainId: any,
  account: AnyArray,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const { data: affiliateTier, mutate: mutateReferrerTier } = useSWR<BigNumber>(
    account && [
      `ReferralStorage:referrerTiers`,
      chainId,
      referralStorageAddress,
      'referrerTiers',
      account,
    ],
    {
      fetcher: contractFetcher(signer, ReferralStorage) as any,
    },
  )
  return {
    affiliateTier,
    mutateReferrerTier,
  }
}

export function useTiers(
  signer: Signer | undefined,
  chainId: number,
  tierLevel?: BigNumberish,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')

  const { data: [totalRebate, discountShare] = [] } = useSWR<BigNumber[]>(
    tierLevel
      ? [
          `ReferralStorage:referrerTiers`,
          chainId,
          referralStorageAddress,
          'tiers',
          tierLevel.toString(),
        ]
      : null,
    {
      fetcher: contractFetcher(signer, ReferralStorage) as any,
    },
  )

  return {
    totalRebate,
    discountShare,
  }
}

export function useUserCodesOnAllChain(account: any) {
  const [data, setData] = useState<any>(null)
  const query = gql`
    query referralCodesOnAllChain($account: String!) {
      referralCodes(first: 1000, where: { owner: $account }) {
        code
      }
    }
  `
  useEffect(() => {
    async function main() {
      const [arbitrumCodes, avalancheCodes] = await Promise.all(
        SUPPORTED_CHAIN_IDS.map(async (chainId) => {
          try {
            const client = getReferralsGraphClient(chainId)
            const { data } = await client.query({
              query,
              variables: { account: (account || '').toLowerCase() },
            })
            return data.referralCodes.map((c: { code: any }) => c.code)
          } catch (ex) {
            return []
          }
        }),
      )
      const [codeOwnersOnAvax = [], codeOwnersOnArbitrum = []] =
        await Promise.all([
          getCodeOwnersData(AVALANCHE, account, arbitrumCodes),
          getCodeOwnersData(ARBITRUM, account, avalancheCodes),
        ])

      setData({
        [ARBITRUM]: codeOwnersOnAvax.reduce((acc, cv) => {
          acc[cv.code] = cv
          return acc
        }, {} as any),
        [AVALANCHE]: codeOwnersOnArbitrum.reduce((acc, cv) => {
          acc[cv.code] = cv
          return acc
        }, {} as any),
      })
    }

    main()
  }, [account, query])
  return data
}

export async function registerReferralCode(
  chainId: number,
  referralCode: any,
  signer: Signer | ethers.providers.Provider | undefined,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  opts: {
    account: string
    value?: number | BigNumber | undefined
    gasLimit?: number | BigNumber | undefined
    sentMsg?: string | undefined
    successMsg?: string | undefined
    hideSentMsg?: boolean | undefined
    hideSuccessMsg?: boolean | undefined
    failMsg?: string | undefined
    setPendingTxns?: (txns: PendingTransaction[]) => void
  },
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const referralCodeHex = encodeReferralCode(referralCode)
  const contract = new ethers.Contract(
    referralStorageAddress,
    ReferralStorage.abi,
    signer,
  )
  const callData = encodeFunctionData({
    abi: ReferralStorage.abi,
    args: [referralCodeHex],
    functionName: 'registerCode',
  })

  const gasLimit = await getGasLimit(
    contract,
    'registerCode',
    [referralCodeHex],
    opts.value,
  )

  const call = {
    address: contract.address,
    calldata: callData,
    gas: BigInt(gasLimit.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  return await sendPaymasterTransaction({
    call,
    account: opts?.account,
    messageOpts,
    router: contract,
    payload: [referralCodeHex],
    method: 'registerCode',
  })
}

export async function setTraderReferralCodeByUser(
  chainId: number,
  referralCode: string,
  signer: Signer | ethers.providers.Provider | undefined,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  opts: {
    account?: any
    successMsg: string | undefined
    failMsg: string | undefined
    setPendingTxns: (txns: PendingTransaction[]) => void
    pendingTxns: PendingTransaction[]
    value?: number | BigNumber | undefined
    gasLimit?: number | BigNumber | undefined
    sentMsg?: string | undefined
    hideSentMsg?: boolean | undefined
    hideSuccessMsg?: boolean | undefined
  },
) {
  const referralCodeHex = encodeReferralCode(referralCode)
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const contract = new ethers.Contract(
    referralStorageAddress,
    ReferralStorage.abi,
    signer,
  )
  const codeOwner = await contract.codeOwners(referralCodeHex)
  if (isAddressZero(codeOwner)) {
    const errorMsg = 'Referral code does not exist'
    helperToast.error(errorMsg)
    return Promise.reject(errorMsg)
  }
  const callData = encodeFunctionData({
    abi: ReferralStorage.abi,
    args: [referralCodeHex],
    functionName: 'setTraderReferralCodeByUser',
  })

  const gasLimit = await getGasLimit(
    contract,
    'setTraderReferralCodeByUser',
    [referralCodeHex],
    opts?.value,
  )

  const call = {
    address: contract.address as Address,
    calldata: callData,
    gas: BigInt(gasLimit.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  return await sendPaymasterTransaction({
    call,
    account: opts.account,
    messageOpts,
    router: contract,
    payload: [referralCodeHex],
    method: 'setTraderReferralCodeByUser',
  })
}

export async function getReferralCodeOwner(
  chainId: number,
  referralCode: string,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const provider = getProvider(undefined, chainId)
  const contract = new ethers.Contract(
    referralStorageAddress,
    ReferralStorage.abi,
    provider,
  )
  const codeOwner = await contract.codeOwners(referralCode)
  return codeOwner
}

export function useUserReferralCode(
  signer: Signer | undefined,
  chainId: any,
  account: any,
  skipLocalReferralCode = false,
) {
  const [localStorageCode, setLocalStorageCode] = React.useState<
    string | undefined
  >()

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const code = window.localStorage.getItem(REFERRAL_CODE_KEY)
      setLocalStorageCode(code || undefined)
    }
  }, [])

  const referralStorageAddress = getContract(
    chainId || DEFAULT_CHAIN_ID,
    'ReferralStorage',
  )
  const { data: onChainCode } = useSWR<string>(
    account && [
      'ReferralStorage',
      chainId || DEFAULT_CHAIN_ID,
      referralStorageAddress,
      'traderReferralCodes',
      account,
    ],
    { fetcher: contractFetcher(signer, ReferralStorage) as any },
  )

  const { data: localStorageCodeOwner } = useSWR<string>(
    localStorageCode && REGEX_VERIFY_BYTES32.test(localStorageCode)
      ? [
          'ReferralStorage',
          chainId || DEFAULT_CHAIN_ID,
          referralStorageAddress,
          'codeOwners',
          localStorageCode,
        ]
      : null,
    { fetcher: contractFetcher(signer, ReferralStorage) as any },
  )

  const {
    attachedOnChain,
    userReferralCode,
    userReferralCodeString,
    referralCodeForTxn,
  } = useMemo(() => {
    let attachedOnChain = false
    let userReferralCode: string | undefined = undefined
    let userReferralCodeString: string | undefined = undefined
    let referralCodeForTxn = ethers.constants.HashZero

    if (skipLocalReferralCode || (onChainCode && !isHashZero(onChainCode))) {
      attachedOnChain = true
      userReferralCode = onChainCode
      userReferralCodeString = decodeReferralCode(onChainCode)
    } else if (localStorageCodeOwner && !isAddressZero(localStorageCodeOwner)) {
      attachedOnChain = false
      userReferralCode = localStorageCode!
      userReferralCodeString = decodeReferralCode(localStorageCode!)
      referralCodeForTxn = localStorageCode!
    }

    return {
      attachedOnChain,
      userReferralCode,
      userReferralCodeString,
      referralCodeForTxn,
    }
  }, [
    localStorageCode,
    localStorageCodeOwner,
    onChainCode,
    skipLocalReferralCode,
  ])

  return {
    userReferralCode,
    userReferralCodeString,
    attachedOnChain,
    referralCodeForTxn,
  }
}

export function useReferrerTier(
  signer: Signer | undefined,
  chainId: any,
  account: any,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const validAccount = useMemo(
    () => (isAddress(account) ? account : null),
    [account],
  )
  const { data: referrerTier, mutate: mutateReferrerTier } = useSWR<BigNumber>(
    validAccount && [
      `ReferralStorage:referrerTiers`,
      chainId,
      referralStorageAddress,
      'referrerTiers',
      validAccount,
    ],
    {
      fetcher: contractFetcher(signer, ReferralStorage) as any,
    },
  )
  return {
    referrerTier,
    mutateReferrerTier,
  }
}

export function useCodeOwner(
  signer: Signer | undefined,
  chainId: any,
  account: string | null | undefined,
  code: any,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const { data: codeOwner, mutate: mutateCodeOwner } = useSWR<string>(
    account &&
      code && [
        `ReferralStorage:codeOwners`,
        chainId,
        referralStorageAddress,
        'codeOwners',
        code,
      ],
    {
      fetcher: contractFetcher(signer, ReferralStorage) as any,
    },
  )
  return {
    codeOwner,
    mutateCodeOwner,
  }
}

export function useReferrerDiscountShare(
  library: Signer | undefined,
  chainId: any,
  owner: string | undefined,
) {
  const referralStorageAddress = getContract(chainId, 'ReferralStorage')
  const { data: discountShare, mutate: mutateDiscountShare } = useSWR<
    BigNumber | undefined
  >(
    owner && [
      `ReferralStorage:referrerDiscountShares`,
      chainId,
      referralStorageAddress,
      'referrerDiscountShares',
      owner.toLowerCase(),
    ],
    {
      fetcher: contractFetcher(library, ReferralStorage) as any,
    },
  )
  return {
    discountShare,
    mutateDiscountShare,
  }
}

export async function validateReferralCodeExists(
  referralCode: any,
  chainId: any,
) {
  const referralCodeBytes32 = encodeReferralCode(referralCode)
  const referralCodeOwner = await getReferralCodeOwner(
    chainId,
    referralCodeBytes32,
  )
  return !isAddressZero(referralCodeOwner)
}

export function useAffiliateCodes(chainId: any, account: any) {
  const [affiliateCodes, setAffiliateCodes] = useState({
    code: null,
    success: false,
  })
  const query = gql`
    query userReferralCodes($account: String!) {
      affiliateStats: affiliateStats(
        first: 1000
        orderBy: volume
        orderDirection: desc
        where: { period: total, affiliate: $account }
      ) {
        referralCode
      }
    }
  `
  useEffect(() => {
    if (!chainId) {
      return
    }
    getReferralsGraphClient(chainId)
      .query({ query, variables: { account: account?.toLowerCase() } })
      .then((res) => {
        const parsedAffiliateCodes = res?.data?.affiliateStats.map(
          (c: { referralCode: string | undefined }) =>
            decodeReferralCode(c?.referralCode),
        )
        setAffiliateCodes({ code: parsedAffiliateCodes[0], success: true })
      })
    return () => {
      setAffiliateCodes({ code: null, success: false })
    }
  }, [chainId, query, account])
  return affiliateCodes
}
